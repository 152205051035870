import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import { Container, Row, Col } from 'react-bootstrap'
import { Section } from '../components/sections'

const Risultati = () => (
<Layout frame='docNav'>

    <Section>
        <Container>
            <Row>
                <Col md={12} className='text-center heading-section'>
                    <h3>Documentazione</h3>

                    <table className='table table-bordered'>
                        <thead>
                            <tr>
                                <th scope='col'>Nome file</th>
                                <th scope='col'>Data</th>
                                <th scope='col'>link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='div-row bg-danger'>
                                <td colSpan='3' className='div-cell'>Presentazioni</td>
                            </tr>
                            <tr>
                                <td>Presentazione</td>
                                <td>06.09.2018</td>
                                <td><a href='resources/doc/presentazione.pptx' download> <span className='fas fa-file-alt' /></a></td>
                            </tr>
                            <tr className='div-row bg-primary'>
                                <td colSpan='3' className='div-cell'>Documenti</td>
                            </tr>
                            <tr>
                                <td>Documento</td>
                                <td />
                                <td><a href='resources/doc/documento.docx' download> <span className='fas fa-file-alt' /></a></td>
                            </tr>
                            <tr className='div-row bg-success'>
                                <td colSpan='3' className='div-cell'>Documenti</td>
                            </tr>
                            <tr>
                                <td>Documento</td>
                                <td />
                                <td><a href='resources/doc/documento.xlsx' download> <span className='fas fa-file-alt' /></a></td>
                            </tr>

                        </tbody>
                    </table>
                </Col>

            </Row>
        </Container>

    </Section>

</Layout>

)

export default Risultati
